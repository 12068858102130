<template>
	<div class="seleDz">
		<div class="left">
			<img src="@/assets/address.png" alt="">
			<div class="addressInfo">
				<div class="xx over">
					{{!addressData?i18n('122'):addressData.city}}-{{!addressData?'':addressData.detail_address}}
				</div>
				<div class="user">
					{{!addressData?i18n('123'):addressData.name}} <span v-if="addressData">{{addressData.phone}}</span>
				</div>
			</div>
		</div>
		<div class="right">
			<div class="" @click="seleAddressOpen">
				{{i18n('166')}}
			</div>
			<div class="" @click="addOpen">
				{{i18n('44')}}
			</div>
		</div>
		<seleAddress ref="seleAddress"></seleAddress>
	</div>
</template>

<script>
	import seleAddress from "@/components/seleAddress/index.vue"
	export default {
		name: "addresss",
		components: {
			seleAddress,
		},
		data() {
			return {

			}
		},
		methods: {
			addOpen(){
				this.$refs.seleAddress.addOpen(1)
			},
			seleAddressOpen() {
				this.$refs.seleAddress.open()
			},
		},
		computed: {
			addressData() {
				let addressData = JSON.parse(JSON.stringify(this.$store.state.serviceArea.addressData))
				if (addressData) {
					addressData.phone = addressData.phone.substring(2)
				}
				return addressData
			},
		},
		created() {
			this.$store.commit('user/setClassifyShow', false)
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.right div:nth-child(1){
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.right div:nth-child(2){
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 4px;
		line-height: 20px;
		padding: 0 6px;
		cursor: pointer;
	}
	.seleDz {
		width: 100%;
		height: 80px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 16px 20px 16px 13px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.seleDz .left {
		display: flex;
	}

	.seleDz .left img {
		width: 20px;
		height: 20px;
		display: block;
		margin-right: 8px;
	}

	.seleDz .left .addressInfo .xx {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 14px;
		font-weight: 500;
		height: 27px;
		padding-top: 3px;
		width: 700px;
	}

	.seleDz .left .addressInfo .user {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #1D2129;
		font-weight: 400;
		opacity: .6;
	}

	.seleDz .left .addressInfo .user span {
		padding-left: 5px;
	}
</style>
