<template>
	<div class="ddzy">
		<div class="tit">
			{{i18n('114')}}
		</div>
		<div class="list">
			<div class="item">
				<div class="left">
					{{i18n('115')}}：
				</div>
				<div class="right">
					<span>₱</span>{{money}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('167')}}：
				</div>
				<div class="right">
					{{freightData}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('168')}}：
				</div>
				<div class="right">
					<span>₱</span>{{yhJe}}
				</div>
			</div>
		</div>
		<div class="list" style="border-bottom: none;">
			<div class="item">
				<div class="left">
					{{i18n('169')}}：
				</div>
				<div class="right">
					<span>₱</span>{{total}}
				</div>
			</div>
		</div>
		<div class="but" @click="sub">
			{{i18n('170')}}
		</div>
	</div>
</template>

<script>
	export default {
		props:['total_money','freightData'],
		name: "ddzy",
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed: {
			money(){
				return this.$store.state.cart.setItems.total_money
			},
			yhJe(){
				return this.money - this.total_money
			},
			total(){
				if(!isNaN(this.freightData)){
					return this.freightData + this.total_money
				}else{
					return this.total_money
				}
			}
		},
		methods: {
			sub(){
				this.$emit("submit")
			}
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.but{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		margin-top: 30px;
		cursor: pointer;
	}
	.list{
		width: 241px;
		margin: 0 auto;
		padding: 12px 0;
		border-bottom: 1px solid #e8e8e8;
	}
	.item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
	}
	.item .left{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.item .right{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F23725;
		text-align: right;
		line-height: 28px;
		font-weight: 400;
	}
	.item .right span{
		font-size: 12px;
	}
	.ddzy{
		width: 289px;
		background: #FFFFFF;
		border-radius: 9px;
		padding-bottom: 20px;
	}
	.tit{
		width: 100%;
		padding-left: 24px;
		height: 48px;
		line-height: 48px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		border-bottom: 1px solid #56AB7B;
		box-sizing: border-box;
	}
</style>