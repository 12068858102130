<template>
	<div class="packa">
		<div class="tit">
			<div :class="genre==1?'left yd':'left js'">
				{{genre==1?i18n('85'):i18n('100')}}
			</div>
			<div class="right" @click="seleTimeOpen">
				{{time}}  <img src="@/assets/rightJt.png" alt="">
			</div>
		</div>
		<div class="tabHeader">
			<div class="">
				{{i18n('126')}}
			</div>
			<div class="">
				{{i18n('127')}}
			</div>
			<div class="">
				{{i18n('78')}}
			</div>
			<div class="">
				{{i18n('128')}}
			</div>
		</div>
		<div :class="isZk?'spListInner':'spListInner spListInnerAc'">
			<spItem v-for="(item,index) in data" :key="index" :data='item'></spItem>
			<div class="zdTxt" v-if="data.length>5" @click="isZk=!isZk">
				{{i18n('130')}}{{data.length-5}}{{i18n('131')}}
				<img src="@/assets/view-list.png" alt="" v-if="isZk">
				<img src="@/assets/view-list-up.png" alt="" v-else>
			</div>
		</div>
		<div class="dhm" v-if="genre!=1">
			<div class="left">
				<div class="label">
					{{i18n('171')}}
				</div>
				<input type="number" :placeholder="i18n('172')" v-model="code">
			</div>
			<div class="right" @click="useCode">
				{{i18n('173')}}
			</div>
		</div>
		<div class="yhq">
			<div class="left">
				<img src="@/assets/yhq.png" alt="">
				{{i18n('22')}} <span>（{{couponNum}}{{i18n('120')}}）</span>
			</div>
			<div class="right">
				<div class="je">
					<span>-₱</span>{{couponData.amount}}
					<div class="" style="display: none;">
						{{money}}
					</div>
				</div>
				<div class="xz" @click="couponOpen">
					<div>
						{{i18n('174')}}
					</div>
					<div>
						{{i18n('175')}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {useCode} from "@/api/coupon.js"
	
	import spItem from './spItem.vue'
	export default {
		props:['data','seleTime','genre','couponNum','couponData'],
		components:{
			spItem
		},
		data() {
			return {
				isZk:true,
				code:'',
			}
		},
		computed:{
			money(){
				let totalPrice = 0
				this.data.map(item=>{
					totalPrice=totalPrice+item.skuAc.price*item.Count
				})
				totalPrice = totalPrice-this.couponData.amount
				if(totalPrice<=0){
					totalPrice = 1
				}
				this.$emit("moneyChange",this.genre,totalPrice)
				return totalPrice
			},
			time(){
				if(this.seleTime.length>0){
					if(this.seleTime[0].genre==this.genre){
						return this.seleTime[0].label
					}else{
						return this.seleTime[1].label
					}
				}else{
					return this.i18n('176')
				}
			}
		},
		watch:{
			money(){
				this.$emit("moneyChange",this.genre,this.money)
			}
		},
		methods: {
			couponOpen(){
				let totalPrice = 0
				this.data.map(item=>{
					totalPrice=totalPrice+item.skuAc.price*item.Count
				})
				this.$emit('couponOpen',this.genre,totalPrice)
			},
			// //使用优惠码
			async useCode(){
				let {data} = await useCode({code:this.code})
				if(data){
					//去更新优惠券列表
					// this.seleCoupon(data)
				}
			},
			seleTimeOpen(){
				this.$emit('seleTimeOpen',this.genre)
			}
		}
	}
</script>

<style scoped>
	.yhq .right{
		display: flex;
		align-items: center;
		padding-right: 24px;
	}
	.yhq .right .je{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F23725;
		text-align: right;
		line-height: 28px;
		font-weight: 400;
		padding-right: 30px;
	}
	.yhq .right .je span{
		font-size: 12px;
	}
	.yhq .right .xz div{
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #56AB7B;
		font-weight: 500;
		text-align: center;
		cursor: pointer;
	}
	.yhq .left{
		display: flex;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 20px;
		font-weight: 500;
	}
	.yhq .left img{
		width: 20px;
		height: 20px;
		display: block;
		margin-right: 8px;
	}
	.yhq .left span {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		line-height: 20px;
		font-weight: 500;
	}
	.dhm,.yhq{
		width: 827px;
		height: 70px;
		background: #FFFFFF;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.dhm .right{
		margin-right: 24px;
		height: 32px;
		background: #56AB7B;
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 400;
		padding: 0 21px;
		line-height: 32px;
		cursor: pointer;
	}
	.dhm .left{
		display: flex;
		align-items: center;
	}
	.dhm .left .label{
		padding-right: 30px;
	}
	.dhm .left .label,.dhm .left input{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #121212;
		font-weight: 500;
	}
	.dhm .left input{
		width: 500px;
	}
	.zdTxt {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 10;
		background: #fff;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		cursor: pointer;
	}
	
	.zdTxt img {
		width: 16px;
		height: 16px;
		opacity: 0.4;
		display: block;
	}
	
	.spListInner {
		max-height: 516px;
		overflow: hidden;
		position: relative;
		padding-bottom: 50px;
		box-sizing: border-box;
	}
	
	.spListInnerAc {
		max-height: none;
	}
	.tabHeader{
		width: 827px;
		margin: 0 auto;
		height: 46px;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		align-items: center;
	}
	.tabHeader div{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
		width: 170px;
		text-align: center;
	}
	.tabHeader div:nth-child(1){
		padding-left: 93px;
		width: 214px;
		text-align: left;
	}
	.packa{
		width: 875px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 16px;
	}
	.tit{
		padding: 0 24px;
		border-bottom: 1px solid #56AB7B;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tit .left{
		height: 18px;
		border-radius: 4px;
		line-height: 18px;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		text-align: center;
		font-weight: 400;
		padding: 0 6px;
	}
	.tit .yd{
		border: 1px solid rgba(255,179,66,1);
		color: #FFB342;
	}
	.tit .js{
		border: 1px solid rgba(86,171,123,1);
		color: #56AB7B;
	}
	.tit .right{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #56AB7B;
		text-align: right;
		font-weight: 400;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.tit .right img{
		width: 16px;
		height: 16px;
		opacity: 0.5;
		display: block;
	}
</style>