<template>
	<div class="yhItem">
		<div :class="data.checked?'one oneAc':'one'" @click="seleCoupon">
			<div class="left">
				<div class="jg">
					{{data.coupon.amount}}<span>₱</span>
				</div>
				<div class="sm" v-if="data.coupon.min_point==0">
					{{i18n('88')}}
				</div>
				<div class="sm" v-else>
					{{i18n('89')}}{{data.coupon.min_point}}{{i18n('90')}}{{data.coupon.amount}}
				</div>
			</div>
			<div class="right">
				<div class="tit">
					<div class="">
						{{lang=='zh'?data.coupon.zh_name:lang=='en'?data.coupon.en_name:data.coupon.vi_name}}
					</div>
					<div class="sy" v-if="type==1">
						{{i18n('91')}}
					</div>
					<div class="sx" v-if="type==2">
						{{i18n('92')}}
					</div>
				</div>
				<div class="bot">
					<div class="sj">
						<div class="gq" v-if="data.yssj<7 && data.yssj>0">
							{{i18n('93')}}
						</div>
						{{data.yssj>0?data.yssj+i18n('95'):i18n('94')}}
					</div>
					<div class="cz" @click.stop="zk=!zk">
						{{!zk?i18n('96'):i18n('97')}} <img src="@/assets/view-list.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="two" v-if="zk">
			{{lang=='zh'?data.coupon.zh_note:lang=='en'?data.coupon.en_note:data.coupon.vi_note}}
		</div>
	</div>
</template>

<script>
	export default {
		name:"yhItem",
		props: ['type', 'data'],
		data() {
			return {
				zk: false
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		mounted() {

		},
		methods: {
			seleCoupon(){
				this.$emit('seleCoupon')
			},
		}
	}
</script>

<style scoped>
	.yhItem{
		width: 428px;
	}
	.two{
		padding: 24px 16px 14px 16px;
		margin-top: -10px;
		background: rgba(86, 171, 123, 0.10);
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		line-height: 20px;
		font-weight: 400;
		border-radius: 6px;
	}
	.one{
		display: flex;
		position: relative;
		overflow: hidden;
		z-index: 20;
		cursor: pointer;
	}
	.oneAc{
		border-radius: 6px;
		border: 1px solid rgba(86,171,123,1);
		box-shadow: 0px 0px 4px 0px rgba(86,171,123,0.5);
	}
	.one::after{
		content: "";
		width: 10px;
		height: 10px;
		border: 1px solid rgba(86,171,123,0.5);
		border-radius: 50%;
		position: absolute;
		left: 92px;
		top: -5px;
		background: #fff;
		z-index: 10;
	}
	.one::before{
		content: "";
		width: 10px;
		height: 10px;
		border: 1px solid rgba(86,171,123,0.5);
		border-radius: 50%;
		position: absolute;
		left: 91px;
		bottom: -5px;
		background: #fff;
		z-index: 10;
	}
	.one .left{
		width: 97px;
		height: 90px;
		background-image: linear-gradient(180deg, #B5D58F 0%, #56AB7B 100%);
		border-radius: 6px 0px 0px 6px;
	}
	.one .left .jg{
		font-family: PingFangSC-Medium;
		font-size: 40px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		font-weight: 500;
		padding-top: 11px;
	}
	.one .left .jg span{
		font-family: Helvetica;
		font-size: 12px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		font-weight: 400;
	}
	.one .left .sm{
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 400;
	}
	.one .right{
		width: 331px;
		height: 88px;
		background: #FFFFFF;
		border: 0.5px solid rgba(86,171,123,0.5);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 0px 6px 6px 0px;
	}
	.one .right .tit{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 10px;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #121212;
		font-weight: 500;
	}
	.one .right .tit div.sx{
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #C9CDD4;
		line-height: 22px;
		font-weight: 500;
	}
	.one .right .tit div.sy{
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #56AB7B;
		line-height: 22px;
		font-weight: 500;
	}
	.one .right .bot{
		padding: 8px 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.one .right .bot .sj{
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #86909C;
		font-weight: 400;
	}
	.one .right .bot .sj .gq{
		width: 60px;
		height: 20px;
		border: 1px solid rgba(86,171,123,1);
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #56AB7B;
		font-weight: 400;
		padding: 0 6px;
		line-height: 20px;
		margin-right: 8px;
	}
	.one .right .bot .cz{
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 10px;
		color: #4E5969;
		font-weight: 400;
		cursor: pointer;
	}
	.one .right .bot .cz img{
		width: 12px;
		height: 12px;
		margin-left: 5px;
		display: block;
	}
</style>
