<template>
	<div class="remark">
		<div class="label">
			{{i18n('177')}}
		</div>
		<input type="text" v-model="value" :placeholder="i18n('178')" @change="valueChange">
	</div>
</template>

<script>
	export default {
		props:['data'],
		name: "remark",
		data() {
			return {
				value:''
			}
		},
		methods: {
			valueChange(){
				this.$emit('remarksChange',this.value)
			}
		},
		created() {
			
		},
		mounted() {
			this.value=this.data
		}
	};
</script>

<style scoped>
	.remark{
		width: 875px;
		height: 70px;
		background: #FFFFFF;
		border-radius: 12px;
		display: flex;
		align-items: center;
		padding: 0 24px;
		box-sizing: border-box;
		margin-bottom: 16px;
	}
	.remark .label{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		padding-right: 30px;
	}
	.remark input{
		flex: 1;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
	}
</style>