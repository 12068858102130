<template>
	<div class="labelView">
		<div class="txt" @click="$router.push('/')">
			{{i18n('02')}}
		</div>
		<div class="gang">
			/
		</div>
		<div class="txt" @click="$router.push('/cart')">
			{{i18n('29')}}
		</div>
		<div class="gang">
			/
		</div>
		<div class="txt">
			{{i18n('116')}}
		</div>
	</div>
</template>

<script>
	export default {
		name: "labelView",
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		created() {
			
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.labelView{
		height: 70px;
		display: flex;
		align-items: center;
	}
	.labelView .txt{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
	}
	.labelView .gang{
		font-size: 12px;
		padding: 0 6px;
		opacity: .6;
		line-height: 22px;
	}
</style>